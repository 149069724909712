<template>
    <div>
        <jy-query ref="form" :model="form" labelWidth="100" class="query_area" size="small">
            <jy-query-item label="产废企业" prop="unitId">
                <el-select v-model="form.unitId">
                    <el-option v-for="u in rUnitList" :key="u.unitId" :value="u.unitId" :label="u.cname"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="固废类别" prop="wasteTId">
                <el-select v-model="form.wasteTId">
                    <el-option v-for="t in wasteTypes" :key="t.wasteTId" :label="t.cName" :value="t.wasteTId"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="抛/重物" prop="nature">
                <el-select v-model="form.nature">
                    <el-option label="抛物" value="1"></el-option>
                    <el-option label="重物" value="0"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="状态" prop="status">
                <el-select v-model="form.status">
                    <el-option v-for="r in statusList" :key="r.status" :value="r.status" :label="r.statusStr"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="发起日期" prop="createT">
                <el-date-picker :clearable="false" :picker-options="monthOptions" type="daterange" value-format="yyyy-MM-dd" v-model="form.createT"></el-date-picker>
            </jy-query-item>
            <jy-query-item label="所属机构" prop="orgId">
                <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="form.orgNa"></el-input>
                </div>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="oncheck" v-if="btnexist('wastesStatisticsOrderCountList')">查询</el-button>
                <el-button size="small" type="primary" @click="resetForm('form')" v-if="btnexist('wastesStatisticsOrderCountReset')">重置</el-button>
                <el-row>
                    <export-excel
                        v-if="btnexist('wastesStatisticsOrderCountExport') && false"
                        baseUrl="/iwastes-admin"
                        :params="form"
                        url="/stat/waste/export"
                        fileName="订单统计"
                        fileType=".xls"
                        size="small"
                    ></export-excel>
                </el-row>
            </template>
        </jy-query>
        <div v-loading="loading">
            <div id="order_count_chart"></div>
            <div class="jy-table">
                <jy-table :data="tableData">
                    <jy-table-column label="序号" type="index" width="55"></jy-table-column>
                    <jy-table-column label="委托单号" prop="wOrderId" show-overflow-tooltip></jy-table-column>
                    <jy-table-column label="产废企业" prop="unitNa"></jy-table-column>
                    <jy-table-column label="固废类别" show-overflow-tooltip>
                        <template v-slot="scope">{{ formatWasteType(scope.row.wasteTId) }}</template>
                    </jy-table-column>
                    <jy-table-column label="拟固废清运量" prop="weight" width="150"></jy-table-column>
                    <jy-table-column label="实际清运总重量" width="150" prop="rWeight"></jy-table-column>
                    <jy-table-column label="产废设施地址" width="150" prop="addr" show-overflow-tooltip></jy-table-column>
                    <jy-table-column label="抛/重物">
                        <template v-slot="scope">{{ scope.row.nature === "1" ? "抛物" : "重物" }}</template>
                    </jy-table-column>
                    <jy-table-column label="状态">
                        <template v-slot="scope">{{ formatStatus(scope.row.status) }}</template>
                    </jy-table-column>
                    <jy-table-column label="联系人" prop="contact"></jy-table-column>
                    <jy-table-column label="联系电话" prop="conPhone" show-overflow-tooltip></jy-table-column>
                    <jy-table-column label="所属行业">
                        <template v-slot="scope">{{ scope.row.unitInfo ? scope.row.unitInfo.industry : "-" }}</template>
                    </jy-table-column>
                    <jy-table-column label="发起人" prop="cUserNa">
                        <template v-slot="scope">{{scope.row.cUserNa || scope.row.unitNa}}</template>
                    </jy-table-column>
                    <jy-table-column label="发起时间" prop="createT" width="150"></jy-table-column>
                    <jy-operate v-bind:list="operateList" width="200"></jy-operate>
                </jy-table>
                <jy-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageIndex"
                    :page-size="pageSize"
                    :total="total"
                ></jy-pagination>
            </div>
        </div>

        <institutions-tree ref="institutions" @addInstitutions="addInstitutions" title="选择所属机构"></institutions-tree>
    </div>
</template>

<script>
    import ExportExcel from "@/components/pages/admin/common/export";
    import * as Eachrts from "echarts";
    import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
    import limitTime from "@/common/js/limitTime.mixin.js";

    import { btnMixins } from "@/common/js/button.mixin";
    export default {
        mixins: [limitTime, btnMixins],
        created() {
            this.initDicts();
            this.setOperateList();
        },
        mounted() {
            this.initTime();
            this.oncheck();
        },
        methods: {
            initDicts() {
                this.$http.post("/wasteOrder/countList").then(res => {
                    this.statusList = res.detail;
                });
                this.$http
                    .post("/unitInfo/queryUnitList", {
                        cName: "",
                        unitType: "0"
                    })
                    .then(res => {
                        this.rUnitList = res.detail;
                    });
                this.$http.post("/wasteType/listAll").then(res => {
                    this.wasteTypes = res.detail;
                });
            },
            formatWasteType(t) {
                const w = this.wasteTypes.find(v => v.wasteTId === t);
                return w ? w.cName : "-";
            },
            formatRUnit(val) {
                const s = this.rUnitList.find(v => v.unitId === val);
                return s ? s.cName : "-";
            },
            formatStatus(val) {
                const s = this.statusList.find(v => v.status === val);
                return s.statusStr;
            },
            initTime() {
                this.form.createT = this.$util.formatInitTime(7, 0);
            },
            oncheck() {
                this.pageIndex = 1;
                this.getList();
                this.getChart();
                console.log("更新");
            },
            getList() {
                let url = "/wasteOrder/pageQuery";
                this.loading = true;
                this.$http
                    .post(
                        url,
                        {
                            ...this.form,
                            pageIndex: this.pageIndex,
                            pageSize: this.pageSize,
                            createBeginTStr: this.$util.addTime(this.form.createT && this.form.createT[0], true),
                            createEndTStr: this.$util.addTime(this.form.createT && this.form.createT[1]),
                            createT: undefined
                        },
                        { isRequestParam: false }
                    )
                    .then(({ detail }) => {
                        this.tableData = detail.list;
                        this.total = detail.total;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            getChart() {
                this.$http
                    .post(
                        "/stat/waste/gather",
                        {
                            ...this.form,
                            createBeginTStr: this.$util.addTime(this.form.createT && this.form.createT[0], true),
                            createEndTStr: this.$util.addTime(this.form.createT && this.form.createT[1]),
                            createT: undefined
                        },
                        { isRequestParam: false }
                    )
                    .then(res => {
                        this.drawChart(res.detail);
                    });
            },
            drawChart(detail) {
                const cIns = Eachrts.init(document.getElementById("order_count_chart"));
                let xAxisData = [],
                    series = [];
                detail.forEach(v => {
                    xAxisData.push(v.date);
                    v.list.forEach(l => {
                        const one = series.find(s => s.name === l.statusStr);
                        if (one) {
                            one.data.push(l.count);
                        } else {
                            series.push({
                                type: "bar",
                                name: l.statusStr,
                                data: [l.count]
                            });
                        }
                    });
                });
                cIns.setOption({
                    title: {
                        text: "委托订单",
                        subtext: "委托订单数"
                    },
                    legend: {
                        right: 20
                    },
                    xAxis: { type: "category", data: xAxisData },
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            type: "shadow"
                        }
                    },
                    yAxis: {
                        type: "value",
                        axisLine: {
                            show: true
                        },
                        axisTick: {
                            show: true
                        }
                    },
                    series
                });
            },
            // 重置
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.form.orgNa = "";
                this.initTime();
                this.oncheck();
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            addInstitutions(data) {
                this.form.orgId = data.orgId;
                this.form.orgNa = data.orgNa;
            },
            // 选择机构树
            selectInstitutions() {
                this.$refs.institutions.init();
            },
            setOperateList() {
                let l = [
                    {
                        name: "查看",
                        icon: "el-icon-edit-outline",
                        fun: this.handleView,
                        isShow: () => {
                            return true;
                        }
                    }
                ];
                this.operateList = l;
            },
            handleView(row) {
                this.$router.push({
                    name: "order",
                    params: {
                        wOrderId: row.wOrderId
                    }
                });
            }
        },
        data() {
            return {
                loading: false,
                tableData: [],
                total: 0,

                form: {
                    unitId: "",
                    wasteTId: "",
                    nature: "",
                    status: "",
                    orgId: "",
                    orgNa: "",
                    createT: []
                },

                pageIndex: 1,
                pageSize: 10,

                rUnitList: [],
                wasteTypes: [],
                statusList: [],
                operateList: [],

                btnMenuId: "wastesStatisticsOrderCount"
            };
        },
        components: {
            ExportExcel,
            institutionsTree
        }
    };
</script>
<style scoped>
    .query_area {
        margin-top: 10px;
    }
    #order_count_chart {
        height: 350px;
    }
</style>
