import { formatInitTime } from "./util";
export default {
    /**
     * 限制查询时间
     * 添加快捷查询
     */
    data() {
        return {
            choiceDate: "",
            // 只允许查询一个星期:7天
            weekOptions: {
                onPick: ({ maxDate, minDate }) => {
                    this.choiceDate = minDate.getTime();
                    if (maxDate) {
                        this.choiceDate = "";
                    }
                },
                shortcuts: [
                    {
                        text: "今天",
                        onClick(picker) {
                            const end = new Date();
                            const startStr = new Date().$Formater("yyyy-MM-dd") + " 00:00:00";
                            const start = new Date(startStr.replace(/-/g, "/"));
                            picker.$emit("pick", [start, end]);
                        }
                    },
                    {
                        text: "昨天",
                        onClick(picker) {
                            const startEnd = formatInitTime(1, 1, true);
                            const start = new Date(startEnd[0].replace(/-/g, "/"));
                            const end = new Date(startEnd[1].replace(/-/g, "/"));
                            picker.$emit("pick", [start, end]);
                        }
                    },
                    {
                        text: "最近3天",
                        onClick(picker) {
                            const startEnd = formatInitTime(2, 0, true);
                            const start = new Date(startEnd[0].replace(/-/g, "/"));
                            const end = new Date(startEnd[1].replace(/-/g, "/"));
                            picker.$emit("pick", [start, end]);
                        }
                    },
                    {
                        text: "最近7天",
                        onClick(picker) {
                            const startEnd = formatInitTime(6, 0, true);
                            const start = new Date(startEnd[0].replace(/-/g, "/"));
                            const end = new Date(startEnd[1].replace(/-/g, "/"));
                            picker.$emit("pick", [start, end]);
                        }
                    }
                ],
                disabledDate: time => {
                    if (this.choiceDate !== "") {
                        const one = 6 * 24 * 60 * 60 * 1000;
                        const minTime = this.choiceDate - one;
                        const maxTime = this.choiceDate + one;
                        return time.getTime() > Date.now() || time.getTime() < minTime || time.getTime() > maxTime;
                    } else {
                        return time.getTime() > Date.now();
                    }
                }
            },

            //限制查询5天
            fiveOptions: {
                onPick: ({ maxDate, minDate }) => {
                    this.choiceDate = minDate.getTime();
                    if (maxDate) {
                        this.choiceDate = "";
                    }
                },
                shortcuts: [
                    {
                        text: "今天",
                        onClick(picker) {
                            const end = new Date();
                            const startStr = new Date().$Formater("yyyy-MM-dd") + " 00:00:00";
                            const start = new Date(startStr.replace(/-/g, "/"));
                            picker.$emit("pick", [start, end]);
                        }
                    },
                    {
                        text: "昨天",
                        onClick(picker) {
                            const startEnd = formatInitTime(1, 1, true);
                            const start = new Date(startEnd[0].replace(/-/g, "/"));
                            const end = new Date(startEnd[1].replace(/-/g, "/"));
                            picker.$emit("pick", [start, end]);
                        }
                    },
                    {
                        text: "最近3天",
                        onClick(picker) {
                            const startEnd = formatInitTime(2, 0, true);
                            const start = new Date(startEnd[0].replace(/-/g, "/"));
                            const end = new Date(startEnd[1].replace(/-/g, "/"));
                            picker.$emit("pick", [start, end]);
                        }
                    }
                ],
                disabledDate: time => {
                    if (this.choiceDate !== "") {
                        const one = 4 * 24 * 60 * 60 * 1000;
                        const minTime = this.choiceDate - one;
                        const maxTime = this.choiceDate + one;
                        return time.getTime() > Date.now() || time.getTime() < minTime || time.getTime() > maxTime;
                    } else {
                        return time.getTime() > Date.now();
                    }
                }
            },
            // 限制查询半个月
            halfMonthOptions: {
                onPick: ({ maxDate, minDate }) => {
                    this.choiceDate = minDate.getTime();
                    if (maxDate) {
                        this.choiceDate = "";
                    }
                },
                disabledDate: time => {
                    if (this.choiceDate !== "") {
                        const one = 14 * 24 * 60 * 60 * 1000;
                        const minTime = this.choiceDate - one;
                        const maxTime = this.choiceDate + one;
                        return time.getTime() > Date.now() || time.getTime() < minTime || time.getTime() > maxTime;
                    } else {
                        return time.getTime() > Date.now();
                    }
                },
                shortcuts: [
                    {
                        text: "今天",
                        onClick(picker) {
                            const end = new Date();
                            const startStr = new Date().$Formater("yyyy-MM-dd") + " 00:00:00";
                            const start = new Date(startStr.replace(/-/g, "/"));
                            picker.$emit("pick", [start, end]);
                        }
                    },
                    {
                        text: "昨天",
                        onClick(picker) {
                            const startEnd = formatInitTime(1, 1, true);
                            const start = new Date(startEnd[0].replace(/-/g, "/"));
                            const end = new Date(startEnd[1].replace(/-/g, "/"));
                            picker.$emit("pick", [start, end]);
                        }
                    },
                    {
                        text: "最近3天",
                        onClick(picker) {
                            const startEnd = formatInitTime(2, 0, true);
                            const start = new Date(startEnd[0].replace(/-/g, "/"));
                            const end = new Date(startEnd[1].replace(/-/g, "/"));
                            picker.$emit("pick", [start, end]);
                        }
                    },
                    {
                        text: "最近7天",
                        onClick(picker) {
                            const startEnd = formatInitTime(6, 0, true);
                            const start = new Date(startEnd[0].replace(/-/g, "/"));
                            const end = new Date(startEnd[1].replace(/-/g, "/"));
                            picker.$emit("pick", [start, end]);
                        }
                    },
                    {
                        text: "最近15天",
                        onClick(picker) {
                            const startEnd = formatInitTime(14, 0, true);
                            const start = new Date(startEnd[0].replace(/-/g, "/"));
                            const end = new Date(startEnd[1].replace(/-/g, "/"));
                            picker.$emit("pick", [start, end]);
                        }
                    }
                ]
            },
            // 只允许查询1个月:30天
            monthOptions: {
                onPick: ({ maxDate, minDate }) => {
                    this.choiceDate = minDate.getTime();
                    if (maxDate) {
                        this.choiceDate = "";
                    }
                },
                disabledDate: time => {
                    if (this.choiceDate !== "") {
                        const one = 29 * 24 * 60 * 60 * 1000;
                        const minTime = this.choiceDate - one;
                        const maxTime = this.choiceDate + one;
                        return time.getTime() > Date.now() || time.getTime() < minTime || time.getTime() > maxTime;
                    } else {
                        return time.getTime() > Date.now();
                    }
                },
                shortcuts: [
                    {
                        text: "最近7天",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
                            picker.$emit("pick", [start, end]);
                        }
                    },
                    {
                        text: "最近14天",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 13);
                            picker.$emit("pick", [start, end]);
                        }
                    },
                    {
                        text: "最近30天",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 29);
                            picker.$emit("pick", [start, end]);
                        }
                    }
                ]
            },
            // 只允许查询3天
            threeDaysOptions: {
                onPick: ({ maxDate, minDate }) => {
                    this.choiceDate = minDate.getTime();
                    if (maxDate) {
                        this.choiceDate = "";
                    }
                },
                disabledDate: time => {
                    if (this.choiceDate !== "") {
                        const one = 2 * 24 * 60 * 60 * 1000;
                        const minTime = this.choiceDate - one;
                        const maxTime = this.choiceDate + one;
                        return time.getTime() > Date.now() || time.getTime() < minTime || time.getTime() > maxTime;
                    } else {
                        return time.getTime() > Date.now();
                    }
                }
            }
        };
    }
};
